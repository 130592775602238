<template>
    <div class="card mb-5">
        <div class="card-body d-flex flex-column align-items-start">
            <h3 class="card-title text-primary" v-html="cardItem.title" ></h3>
            <div class="card-text" v-html="cardItem.content"></div>

            <ul class="list-unstyled mt-auto mb-0">
                <li v-for="link in cardItem.links" :key="link">
					<a class="btn btn-link btn-link--secondary mt-auto px-0 text-decoration-underline" target="_blank" :href="link.file_link || link.link" :click="clickLink(link)">{{link.label_text}}
					</a>
				</li>
            </ul>
            
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Card',
        props: {
            cardItem: {type: Object, required: true},
        },
        methods: {
            clickLink(link) {
                if (typeof this.$gtag == 'undefined') {
                    return;
				}
                
                this.$gtag.event('click_link', {
                    'event_category' : 'links',
                    'event_label' : link.file_link || link.link
                });
            }
        }
    }
</script>